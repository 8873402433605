<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)" @resized="setTableHeight" v-resize="setTableHeight">
			<pane min-size="20" size="35" ref="toppane">
				<div>
					<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
						:options.sync="options" :server-items-length="totalItems"
						:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header :height="tableheight"
						:loading="loading" loading-text="加载中..." show-select single-select>
					<template v-slot:top>
						<div class="d-flex flex-wrap pt-3 pb-1 pl-1" style="gap:16px" ref="tabletopbar">
							<v-btn-toggle mandatory dense v-model="filter" @change="fetchData">
								<v-btn>等待手术</v-btn>
								<v-btn>手术完成</v-btn>
							</v-btn-toggle>
							<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
								transition="scale-transition" offset-y min-width="290px">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field v-model="pdate1" label="预约日期起" readonly v-bind="attrs"
										v-on="on" dense hide-details clearable style="flex: 0 1 auto;max-width:150px;"></v-text-field>
								</template>
								<v-date-picker v-model="pdate1" @input="menu1 = false" no-title scrollable></v-date-picker>
							</v-menu>
							<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10"
								transition="scale-transition" offset-y min-width="290px">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field v-model="pdate2" label="预约日期止" readonly v-bind="attrs"
										v-on="on" dense hide-details clearable style="flex: 0 1 auto;max-width:150px;"></v-text-field>
								</template>
								<v-date-picker v-model="pdate2" @input="menu2 = false" no-title scrollable></v-date-picker>
							</v-menu>
							<v-text-field placeholder="姓名" v-model="pname" dense clearable hide-details style="flex: 0 1 auto"
								append-icon="search" @click:append="fetchData" @keyup.enter.native="fetchData"/>
						</div>
						<v-divider/>
					</template>
					<template v-slot:item.ho="{ item }">
						<v-tooltip v-if="!!item.HistoryOperatives" bottom>
							<template v-slot:activator="{ on }">
								<v-icon v-on="on" color="#ce5925" @click.stop="showHistoryOper(item)">mdi-account-heart-outline</v-icon>
							</template>
							<span v-html="item.HistoryOperatives"/>
						</v-tooltip>
					</template>
					<template v-slot:item.sticker="{ item }">
						<v-icon @click.stop="printSticker(item)">mdi-barcode</v-icon>
					</template>
					<template v-slot:item.checkin="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon v-on="on" :color="item.progress.endoscopyCheckin ? 'green' : undefined"
									@click.stop="showCheckin(item)">
									how_to_reg
								</v-icon>
							</template>
							{{item.progress.endoscopyCheckin ? '已到诊' : '到诊确认'}}
						</v-tooltip>
					</template>
					<template v-slot:item.actions="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon v-on="on" @click="editNote(item)" class="mr-1" :color="item.color">edit_note</v-icon>
							</template>
							患者备注
						</v-tooltip>
					</template>
					<template v-slot:item.apntOprtDate="{ item }">
						{{formatTime(item.apntOprtDate)}}
					</template>
					<template v-slot:item.progress.endoscopyCheckin="{ item }">
						{{formatTime(item.progress.endoscopyCheckin)}}
					</template>
					<template v-slot:item.progress.operative="{ item }">
						{{formatTime(item.progress.operative)}}
					</template>
					<template v-slot:item.idcard="{ item }">
						{{getMaskedIdcard(item)}}
					</template>
					<template v-slot:item.gender="{ item }">
						{{getGender(item)}}
					</template>
					<template v-slot:item.age="{ item }">
						{{getAge(item)}}
					</template>
					<template v-slot:item.opts="{ item }">
						{{getOptsStr(item)}}
					</template>
					<template v-slot:item.progress.prepare="{ item }">
						<span v-if="!item.progress.prepare" class="warning--text font-weight-bold">未完成</span>
						<span v-else>已完成</span>
					</template>
					<template v-slot:item.progress.charging="{ item }">
						{{formatTime(item.progress.charging)}}
					</template>
					<template v-slot:item.orderSource="{ item }">
						<span :class="item.orderSource === '定向' ? 'purple--text font-weight-bold' : 'black--text'">{{item.orderSource}}</span>
					</template>
					<template v-slot:item.insurance="{ item }">
						<span :class="item.payment.insurance ? 'teal--text text--accent-4 font-weight-bold' : 'black--text'">{{getInsurance(item)}}</span>
					</template>
					<template v-slot:item.apntOprtDoctor="props">
						<v-edit-dialog large :return-value.sync="props.item.apntOprtDoctor"
							save-text="保存" cancel-text="取消" @save="saveItem('apntOprtDoctor', props)">
							{{props.item.apntOprtDoctor}}
							<template v-slot:input>
								<v-select :items="doctors_t" v-model="props.item.apntOprtDoctor"/>
							</template>
						</v-edit-dialog>
					</template>
					</v-data-table>
				</div>
			</pane>
			<pane min-size="20" size="65" v-if="(selected.length > 0)">
				<div style="max-height: 100%" class="overflow-y-auto pl-4">
                <v-tabs v-model="tab" style="position:sticky;top:0;z-index:99;">
                    <v-tab>健康问卷</v-tab>
                    <v-tab>评估问卷</v-tab>
                    <v-tab>病历记录</v-tab>
                    <v-tab>检查结果</v-tab>
					<v-tab>手术结果</v-tab>
                    <v-tab>计费</v-tab>
					<v-tab>病理申请</v-tab>
					<v-tab>专家建议</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-list-item v-for="(item, i) in selected[0].QA.questionnaire" :key="item.category" dense>
                            <v-list-item-content>
                                <v-list-item-title>{{item.category}}</v-list-item-title>
                                <v-checkbox v-for="q in item.questions" :key="q.Q" :label="q.Q" :input-value="q.A==='1'"
                                    readonly class="ml-6 mt-0" hide-details>
                                </v-checkbox>
                            </v-list-item-content>
                        </v-list-item>
                    </v-tab-item>
                    <v-tab-item>
						<eval-q ref="evalq" :patient="selected[0]" readonly/>
						<v-btn color="primary" :disabled="loading" @click.stop="printEvalQ" width="100" class="mt-2 mb-6 ml-4">打印</v-btn>
						<v-btn color="primary" :disabled="loading" @click.stop="printEvalMR" width="100" class="mt-2 mb-6 ml-4">生成病历</v-btn>
                    </v-tab-item>
                    <v-tab-item>
						<div>
							<v-btn class="ml-8 mt-6 mb-4" rounded width="120" color="primary" @click.stop="addRecord">添加病历</v-btn>
							<diagnose-history :items="MedicalHistory" @edit="editRecord" @remove="removeRecord"/>
						</div>
                    </v-tab-item>
                    <v-tab-item>
                        <v-container fluid>
                            <v-row>
                                <v-card v-for="(r,idx) in eeresults" :key="idx" class="ma-2" max-width="300">
                                    <v-img :src="r" lazy-src="../assets/logo.png" width="300" height="300" @click="showImage(idx)">
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </v-card>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item>
						<v-form ref="ecform">
							<div class="d-flex" style="max-width:650px;gap:24px;">
								<v-select label="手术医生" :items="doctors_t" v-model="endoscopyResult.doctorT" :rules="acRules"/>
								<v-select label="麻醉医生" :items="doctors_mz" v-model="endoscopyResult.doctorMZ" :rules="acRules"/>
								<v-select label="病理机构" :items="pathology_agencies" v-model="pathologyAgency" clearable/>
							</div>
							<v-simple-table style="border:1px solid #cccccc;max-width:650px;margin-bottom:12px;">
								<thead style="background-color: #eee">
									<tr>
										<th>项目</th>
										<th>胃镜</th>
										<th>肠镜</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>活检</td>
										<td><v-text-field type="number" single-line dense hide-details suffix="块" class="edres" v-model.number="endoscopyResult.biopsy_w" :min="0"/></td>
										<td><v-text-field type="number" single-line dense hide-details suffix="块" class="edres" v-model.number="endoscopyResult.biopsy_c" :min="0"/></td>
									</tr>
									<tr>
										<td>息肉</td>
										<td><v-text-field type="number" single-line dense hide-details suffix="块" class="edres" v-model.number="endoscopyResult.polyp_w" :min="0"/></td>
										<td><v-text-field type="number" single-line dense hide-details suffix="块" class="edres" v-model.number="endoscopyResult.polyp_c" :min="0"/></td>
									</tr>
									<tr>
										<td>C2</td>
										<td>
											<v-radio-group dense v-model="endoscopyResult.needFollowup_w" row>
												<v-radio label="否" value="否"/>
												<v-radio label="是" value="是"/>
											</v-radio-group>
										</td>
										<td>
											<v-radio-group dense v-model="endoscopyResult.needFollowup_c" row>
												<v-radio label="否" value="否"/>
												<v-radio label="是" value="是"/>
											</v-radio-group>
										</td>
									</tr>
									<tr>
										<td>病理</td>
										<td>
											<v-text-field type="number" single-line dense hide-details suffix="瓶" class="edres" v-model.number="endoscopyResult.pathology_p_w" :min="0"/>
											<v-text-field type="number" single-line dense hide-details suffix="块" class="edres" v-model.number="endoscopyResult.pathology_k_w" :min="0"/>
										</td>
										<td>
											<v-text-field type="number" single-line dense hide-details suffix="瓶" class="edres" v-model.number="endoscopyResult.pathology_p_c" :min="0"/>
											<v-text-field type="number" single-line dense hide-details suffix="块" class="edres" v-model.number="endoscopyResult.pathology_k_c" :min="0"/>
										</td>
									</tr>
									<tr>
										<td>复诊周期(月)</td>
										<td>
											<v-select dense hide-details :items="[3,6,12,24,36,60,120]" v-model="periodw" clearable style="width:100px" :disabled="!!endoscopyResult.pathology_k_w"/>
										</td>
										<td>
											<v-select dense hide-details :items="[3,6,12,24,36,60,120]" v-model="periodc" clearable style="width:100px" :disabled="!!endoscopyResult.pathology_k_c"/>
										</td>
									</tr>
								</tbody>
							</v-simple-table>
							<v-btn rounded color="primary" width="120" class="mt-2 mb-4" @click.stop="saveResult"
								:loading="saving" :disabled="saving">提交</v-btn>
							<!--<v-btn rounded color="primary" width="120" class="mt-2 mb-4" @click.stop="showLevel" outlined>术后等级</v-btn>-->
						</v-form>
                    </v-tab-item>
                    <v-tab-item style="max-width:800px;">
						<div class="d-flex justify-end my-2">
							<v-select outlined :items="quotations" item-text="name" return-object v-model="quotation" label="计费模板" style="max-width:150px" dense hide-details @change="onQuotationChanged"/>
						</div>
						<v-simple-table style="border:1px solid #cccccc;" dense>
							<template v-slot:default>
							<thead style="background-color: #eee">
								<tr>
									<th>诊疗项目</th>
									<th>计价说明</th>
									<th>数量</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="(item, index) in quotation.treatment">
								<tr v-if="item.enabled || !!charges[index].quantity"  >
									<td width="50%">{{ item.subject }}</td>
									<td width="40%">{{ item.description }}</td>
									<td width="10%">
										<v-text-field
										v-model.number="charges[index].quantity"
										type="number"
										single-line
										dense
										hide-details
										:min="0"
										:readonly="!!item.g"
										:filled="!!item.g"
										/>
									</td>
								</tr>
								</template>
							</tbody>
							</template>
						</v-simple-table>
						<!--<div class="mt-4 mb-8" style="text-align:right">-->
						<v-card-actions class="mt-4 mb-8">
							<v-text-field label="合计" :value="chargeSum" dense hide-details readonly style="max-width:120px"/>
							<v-select label="折扣" :items="discountList" v-model="discount" dense hide-details style="max-width:120px;margin-left:20px;"/>
							<v-text-field label="折后价格" :value="valueAmount" dense hide-details readonly style="max-width:120px;margin-left:20px;"/>
							<v-spacer/>
							<v-btn class="primary" style="width:120px" :loading="saving" :disabled="saving" @click.stop="saveCharges">保存</v-btn>
						</v-card-actions>
                    </v-tab-item>
                    <v-tab-item>
						<div v-if="selected[0].reports && !!selected[0].reports['胃镜']">
							<v-row no-gutters style="width:100%">
								<v-col cols="3" class="pa-1">
									<form>
										<v-text-field outlined label="临床病历摘要（胃）" v-model.trim="pathologyRequestW.mr"/>
										<v-text-field outlined label="标本件数（胃）" v-model.number="pathologyRequestW.quantity" type="number" :min="0"/>
										<v-textarea outlined label="检查材料（胃）" v-model.trim="pathologyRequestW.samples" hide-details/>
									</form>
								</v-col>
								<v-col cols="9" class="pa-1">
									<form>
										<v-textarea outlined filled readonly label="检查所见（胃）" :value="selected[0].reports['胃镜'].findings" :rows="11" height="322" hide-details/>
									</form>
								</v-col>
							</v-row>
							<v-btn class="primary ml-1 my-2" @click.stop="requestForm('胃')">病理申请（胃）</v-btn>
						</div>
						<hr class="mt-2"/>
						<div class="mt-4" v-if="selected[0].reports && !!selected[0].reports['肠镜']">
							<v-row no-gutters style="width:100%">
								<v-col cols="3" class="pa-1">
									<form>
										<v-text-field outlined label="临床病历摘要（肠）" v-model.trim="pathologyRequestC.mr"/>
										<v-text-field outlined label="标本件数（肠）" v-model.number="pathologyRequestC.quantity" type="number" :min="0"/>
										<v-textarea outlined label="检查材料（肠）" v-model.trim="pathologyRequestC.samples" hide-details/>
									</form>
								</v-col>
								<v-col cols="9" class="pa-1">
									<form>
										<v-textarea outlined filled readonly label="检查所见（肠）" :value="selected[0].reports['肠镜'].findings" :rows="11" height="322" hide-details/>
									</form>
								</v-col>
							</v-row>
							<v-btn class="primary ml-1 my-2" @click.stop="requestForm('肠')">病理申请（肠）</v-btn>
						</div>
                    </v-tab-item>
					<v-tab-item class="pt-4 pb-4">
						<v-textarea outlined hide-details auto-grow class="mb-2" style="width:500px" rows="10" label="专家建议" v-model.trim="expertAdvice"/>
						<v-btn rounded large color="primary" width="180" class="ml-4" @click.stop="saveExpertAdvice"
							:loading="loading" :disabled="loading">保存</v-btn>
					</v-tab-item>
                </v-tabs-items>
	            </div>
			</pane>
		</splitpanes>
		<viewer @inited="inited" ref="viewer" :images="eeresults" style="display:none">
			<template slot-scope="scope">
				<img v-for="src in scope.images" :src="src" :key="src">
			</template>
		</viewer>
		<diagnose v-model="diagnose" :patient="selected[0]" :mrindex="mrindex" @change="fetchMr"/>
		<v-dialog v-model="checkin" width="300">
			<v-card>
				<v-card-title>客户到诊</v-card-title>
				<v-card-text>
					<v-form readonly>
						<v-text-field label="姓名" :value="pii.username"/>
						<v-text-field label="证件号码" :value="pii.idcard"/>
						<v-text-field label="电话号" :value="pii.phone"/>
					</v-form>
				</v-card-text>
				<v-divider/>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="primary" @click.stop="doCheckin">确认到诊</v-btn>
					<v-btn outlined @click.stop="checkin=false">返回</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import {formatTime, calculateAge, formatDate, addMonths, pathology_agencies} from '../utils'
	import {printSticker} from '../zebra'
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'
	import store from '../store.js'
	import DiagnoseHistory from '../components/DiagnoseHistory.vue';
	import Diagnose from '../components/Diagnose.vue';
	import EvalQ from '../components/EvalQ.vue';

	import { saveAs } from 'file-saver'
	import JSZip from 'jszip'
	import JSZipUtils from 'jszip-utils'
	import Docxtemplater from "docxtemplater"
	import ImageModule from 'docxtemplater-image-module-free'
	import {setPatientColor} from '../workset3'
	import PatientNote from '../components/PatientNote.vue'

    export default {
        data() {
            return {
                authed: false,
                headers: [
					{text:'', value:'ho', width:28, class:'bcicon', cellClass:'bcicon'},
                    {text:'', value:'sticker', width:24, sortable: false, cellClass:'bcicon'},
                    {text:'', value:'checkin', width:24, sortable: false, cellClass:'bcicon'},
                    {text:'', value:'actions', width:40, sortable: false, class:'px-0', cellClass:'px-0'},
                    {text:'病案号', value:'pid', width:100},
                    {text:'姓名', value:'pii.username', width:100},
                    {text:'性别', value:'gender', width:80},
                    {text:'年龄', value:'age', width:80},
                    {text:'出生日期', value:'pii.dob', width:110},
                    {text:'套餐内容', value:'opts', width:180, sortable: false},
                    {text:'术中治疗', value:'opts.TX', width:100},
                    {text:'术前准备', value:'progress.prepare', width:100},
                    {text:'预约医生', value:'apntOprtDoctor', width:100},
                    {text:'预约手术时间', value:'apntOprtDate', width:180},
                    {text:'到诊时间', value:'progress.endoscopyCheckin', width:180},
                    {text:'手术完成时间', value:'progress.operative', width:180},
                    {text:'计费时间', value:'progress.charging', width:180},
                    {text:'身高', value:'QA.baseinfo.bodyheight', width:80},
                    {text:'体重', value:'QA.baseinfo.bodyweight', width:80},
                    {text:'证件号', value:'idcard', width:120},
                    {text:'送病理', value:'endoscopyResult.pathology', width:120},
                    {text:'术后等级', value:'endoscopyResult.followupLevel', width:120},
                    {text:'已选专家', value:'doctorName', width:100},
                    {text:'手术医生', value:'endoscopyResult.doctorT', width:100},
                    {text:'麻醉医生', value:'endoscopyResult.doctorMZ', width:100},
                    {text:'健康顾问', value:'consultantName', width:150},
                    {text:'支付方', value:'insurance', width:150},
                    {text:'渠道', value:'orderSource', width:80},
                    {text:'订单号', value:'_id', width:100},
                    {text:'诊所', value:'region', width:100},
                ],
                items: [],
                loading: false,
				selected: [],
				endoscopyResult: {
					biopsy: '',
					polyp: '',
					needFollowup: '',
					pathology: '',
					followupLevel: '',
					doctorT: '',
					doctorMZ: '',
				},
				saving: false,
				filter: 0,
				options: {sortBy:['apntOprtDate'], sortDesc:[false]},
				totalItems: 0,
				pdate1: '',
				menu1: false,
				pdate2: '',
				menu2: false,
				pname: '',
				historyItems: [],
				eeresults: [],
				acRules: [v => !!v || '此项为必选'],
				quotation: {},		//quotation内容只能增不能减
				quotations: [],
				emptyCharges: [],
                charges: [],
				diagnose: false,
				tableheight: undefined,
				mrindex: -1,
				doctors_t: [],
				doctors_mz: [],
				discountList: [
					{text:'100%', value:1.0},
					{text:'80%', value:0.8},
					{text:'60%', value:0.6},
					{text:'50%', value:0.5},
					{text:'0%', value:0}
				],
				discount: 1.0,
				MedicalHistory: [],
				tab: null,
				pathology_agencies,
				pathologyAgency: null,
				checkin: false,
				checkinItem: null,
				pii: {},
				periodw: null,
				periodc: null,
				pathologyRequestW: {
					mr: '筛查',
					quantity: null,
					samples: null,
				},
				pathologyRequestC: {
					mr: '筛查',
					quantity: null,
					samples: null,
				},
				expertAdvice: '',
            }
        },
		computed: {
			chargeSum() {
				if (!this.quotation.treatment) return 0;
				let sum = 0;
				const t = this.charges.reduce((acc, cur)=>{
					acc[cur.id] = cur;
					return acc;
				}, {});
				for (let index in this.quotation.treatment) {
					if (this.quotation.treatment[index].g) {
						this.charges[index].quantity = this.quotation.treatment[index].g(t);
					}
					if (this.charges[index].quantity) {
						sum += this.quotation.treatment[index].f(this.charges[index].quantity);
					}
				}
				return sum;
			},
			valueAmount() {
				return Math.round(this.chargeSum * this.discount);
			},
		},
        mounted() {
			this.authed = this.$hasPrivilege('手术管理');
			if (!this.authed) return;
			this.formatTime = formatTime;
			this.pdate1 = formatDate(Date.now());
			this.pdate2 = formatDate(Date.now());
			this.$watch(()=>store.currentRegion, this.fetchData);
			this.fetchQuotation();
			this.fetchDoctors();
			this.fetchData();
        },
        methods: {
			setTableHeight() {
				if (!this.$vuetify.breakpoint.mobile && this.selected.length === 0) {
					this.tableheight = undefined;
					return;
				}
				const tableFooterHeight = 59;
				const ttbh = this.$refs.tabletopbar.getBoundingClientRect().height;
				setTimeout(() => {
					const paneHeight = this.$refs.toppane.$el.getBoundingClientRect().height;
					this.tableheight = paneHeight - tableFooterHeight - ttbh;
					if (this.selected.length > 0) this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected", { container: ".v-data-table__wrapper" }) );
				}, 200)
			},
			async fetchMr() {
				this.MedicalHistory = [];
				const db = this.$tcbapp.database();
				const _ = db.command;
				try {
					const res = await db.collection('wp2order')
						.where({
							pid: this.selected[0].pid,
							MedicalHistory: _.exists(true),
						})
						.field({MedicalHistory:true})
						.get();
					const r = res.data.find(x => x._id === this.selected[0]._id);
					if (r) {
						this.selected[0].MedicalHistory = r.MedicalHistory;
					}
					this.MedicalHistory = res.data
						.flatMap(x => {
							x.MedicalHistory.forEach((y, index) => {
								if (x._id === this.selected[0]._id) {
									y.id = x._id;
									y.mrindex = index;
								}
							});
							return x.MedicalHistory;
						})
						.sort((a, b) => a.time - b.time);
				} catch(err) {
					console.error(err);
				}
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				const $ = _.aggregate;
				const filters = [
					{'endoscopyResult':_.exists(false)},
					{'endoscopyResult':_.exists(true)},
				];
				let filter = [];
				const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
				const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
				if (t1 && t2) {
					filter.push({apntOprtDate:_.gt(t1).lt(t2)});
				} else if (this.pdate1) {
					filter.push({apntOprtDate:_.gt(t1)});
				} else if (this.pdate2) {
					filter.push({apntOprtDate:_.lt(t2)});
				} else {
					filter.push({apntOprtDate:_.exists(true)});
				}
				if (this.pname) {
					filter.push({'pii.username':this.pname});
				}
				filter.push({hide:_.neq(true)});
				filter.push({region:store.currentRegion});
				const f = filter.concat(filters[this.filter]);
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					const pids = res.data.map(x => x.pid);
					const r = await db.collection('wp2order').aggregate()
						.match({pid:_.in(pids), 'progress.operative':_.exists(true)})
						.group({_id:'$pid', ids:$.push($.concat([
							$.dateToString({date:'$progress.operative',format: '%Y-%m-%d'}),
							' ',
							'$opts.GIE'
						]))})
						.end();
					res.data.forEach(x => {
						let a = r.data.find(y => y._id === x.pid)?.ids;
						if (a?.length > 0) {
							a = ['历史检查：', ...a];
							x.HistoryOperatives = a.join('<br>')
						}
					});
					await setPatientColor(db, res);
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			inited(viewer) {
				this.$viewer = viewer;
			},
			showImage(idx) {
				if (this.eeresults.length === 0) return;
				this.$viewer.show();
				this.$viewer.view(idx);
			},
			getMaskedIdcard(item) {
				return item.pii.idcard;
			},
            getGender(item) {
                return item.pii.gender;
            },
            getAge(item) {
                return calculateAge(item.pii.dob);
            },
            getOptsStr(item) {
				return item.opts.ANES + item.opts.GIE + '/' + item.opts.PRO;
			},
			getInsurance(item) {
				return item.payment.insurance && item.payment.insurer ? `${item.payment.insurer} - ${item.payment.insurance}` : '自费';
			},
			async saveResult() {
				if (!this.$refs.ecform.validate()) return;
				const endoscopyResult = {...this.endoscopyResult, followupLevel: this.getFollowupLevel(this.endoscopyResult)};
				if ((endoscopyResult.pathology_p_w || endoscopyResult.pathology_p_c) && !this.pathologyAgency) {
					this.$dialog.message.error('请选取病理机构');
					return;
				}
				if ((endoscopyResult.pathology_p_w > endoscopyResult.pathology_k_w)
				 || (endoscopyResult.pathology_p_c > endoscopyResult.pathology_k_c)
				 || (!endoscopyResult.pathology_p_w !== !endoscopyResult.pathology_k_w)		//logic xor
				 || (!endoscopyResult.pathology_p_c !== !endoscopyResult.pathology_k_c)) {
					this.$dialog.message.error('病理数量不合理');
					return;
				}
				const has_w = ['胃镜','胃肠镜'].includes(this.selected[0].opts.GIE);
				const has_c = ['肠镜','胃肠镜'].includes(this.selected[0].opts.GIE);
				if (has_w && !endoscopyResult.needFollowup_w) {
					this.$dialog.message.error('请选择胃镜是否C2');
					return;
				}
				if (has_c && !endoscopyResult.needFollowup_c) {
					this.$dialog.message.error('请选择肠镜是否C2');
					return;
				}
				//需求47
				if (has_w && !endoscopyResult.pathology_k_w && !endoscopyResult.pathology_k_c && !this.periodw) {
					this.$dialog.message.error('请填写胃镜复诊周期');
					return;
				}
				if (has_c && !endoscopyResult.pathology_k_w && !endoscopyResult.pathology_k_c && !this.periodc) {
					this.$dialog.message.error('请填写肠镜复诊周期');
					return;
				}
				endoscopyResult.needFollowup = (endoscopyResult.needFollowup_w === '是') || (endoscopyResult.needFollowup_c === '是') ? '是' : '否';
				endoscopyResult.pathology = (!!endoscopyResult.pathology_p_w || !!endoscopyResult.pathology_p_c) ? '有' : '无';
				endoscopyResult.biopsy = `胃${endoscopyResult.biopsy_w||0} 肠${endoscopyResult.biopsy_c||0}`;
				endoscopyResult.polyp = `胃${endoscopyResult.polyp_w||0} 肠${endoscopyResult.polyp_c||0}`;
				const id = this.selected[0]._id;
                this.saving = true;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{
						funcname:'saveEndoscopyResult',
						data: {
							id,
							endoscopyResult,
							pathologyAgency: this.pathologyAgency,
						}
					}});
					if (this.periodw || this.periodc) {
						const estTimew = this.periodw ? addMonths(this.periodw) : undefined;
						const estTimec = this.periodc ? addMonths(this.periodc) : undefined;
						const subsequent = {
							according: '诊断',
							periodw: this.periodw,
							periodc: this.periodc,
							tx: '',
							exam: '',
							estTimew,
							estTimec,
						}
						await this.$tcbapp.callFunction({name:"wp2mp", data:{
							funcname:'issueSubsequent',
							data: {
								id,
								subsequent
							}
						}});
					}
					await this.fetchData();
					this.$dialog.message.success('保存完成');
				} catch(err) {
					console.error(err);				
					this.$dialog.message.error('保存失败');
				}
				this.saving = false;
			},
			showLevel() {
				if (!this.$refs.ecform.validate()) return;
				const r = this.getFollowupLevel(this.endoscopyResult);
				this.$dialog.message.info(r);
			},
			getFollowupLevel(r) {
				/*	
				if (r.needFollowup_w === '否' && r.needFollowup_c  === '否') {
					if (!r.biopsy_w && !r.biopsy_c && !r.polyp_w && !r.polyp_c) {
						return 'A'
					}
					return 'B'
				}
				return 'C'
				*/
				//2024-04-07 运营需求42
				const item = this.selected[0];
                const age = calculateAge(item.pii.dob);
				let f1 = false;
				if (item.QA && item.QA.questionnaire) {
					const q1 = item.QA.questionnaire.flatMap(x => x.questions).find(x => x.Q === '消化道出血');
					f1 = q1 && q1.A == '1';
				}
				const f2 = item.evalConclusion && item.evalConclusion.gastroPrompt1;	//停抗凝药
				if (r.needFollowup_w !== '是' && r.needFollowup_c  !== '是') {
					const n = (r.biopsy_w||0) + (r.biopsy_c||0) + (r.polyp_w||0) + (r.polyp_c||0);
					if (n > 4) {
						return 'C1'
					} else if (n == 0) {
						return 'A'
					} else if (age >= 65 || f1 || f2) {
						return 'C1'
					} else {
						return 'B'
					}
				}
				return 'C2'
			},
			async fetchQuotation() {
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp", data:{
						funcname:'getTreatmentQuotation2',
						data: {}
					}});
					const r = JSON.parse(res.result, (key, value) => {
						if (key === 'f' || key === 'g') {
							return eval(value);
						}
						return value;
					});
					this.quotations = r;
					this.quotation = this.quotations[0];
					this.charges = this.quotation.treatment.map(x => ({id:x.id, quantity:null}));
				} catch(err) {
					console.error(err);				
				}
			},
			async saveCharges() {
                this.saving = true;
				const id = this.selected[0]._id;
				const qname = this.quotation.name;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{
						funcname:'charging',
						data: {
							id,
							qname,
							charges: this.charges
						}
					}});
					this.$dialog.message.success('保存完成');
					await this.fetchData();
				} catch(err) {
					console.error(err);				
					this.$dialog.message.error('保存失败');
				}
				this.saving = false;
			},
			addRecord() {
				this.mrindex = -1;
				this.diagnose = true;
			},
			editRecord(idx) {
				this.mrindex = idx;
				this.diagnose = true;
			},
			async removeRecord(index) {
				const res = await this.$dialog.warning({
					text: '确定要删除此项记录？',
					title: '提示'
				});
				if (!res) return;
				const id = this.selected[0]._id;
				this.loading = true;
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'removeMedicalRecord',
							data: {
								id,
								index,
							}
						}
					});
					if (!res.result.ret) {
						this.$dialog.error({title:'删除失败', text:res.result.msg});
						return;
					}
					this.fetchMr();
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('删除失败');
				} finally {
					this.loading = false;
				}
			},
			async fetchDoctors() {
				try {
					const db = this.$tcbapp.database();
					const _ = db.command;
					const res = await db.collection('wp2doctor')
						.where(_.and(
							_.or([
								_.and({rank:_.gt(0)}, {duty:_.in(['t'])}),
								{duty:_.in(['bt'])},
								{appointed: true},
								{duty:_.in(['mz'])}
							]),
							{region:_.elemMatch(_.eq(store.currentRegion))}
						))
						.orderBy('rank','desc')
						.field({name:true, duty:true})
						.get();
					this.doctors_t = res.data.filter(x => x.duty.some(y => ['bt', 't'].includes(y))).map(x => x.name);
					this.doctors_mz = res.data.filter(x => x.duty.some(y => ['mz'].includes(y))).map(x => x.name);
					this.doctors_mz.unshift('无');
				} catch(err) {
					console.error(err);
				}
			},
			onQuotationChanged(newq) {
				this.charges = newq.treatment.map(x => ({id:x.id, quantity:null}));
			},
			showHistoryOper(item) {

			},
			async saveItem(key, props) {
				await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'editInfo',
					data:{
						id: props.item._id,
						key,
						value: props.value
					}
				}});
			},
			printEvalQ() {
				this.$refs.evalq.print();
			},
			printEvalMR() {
				this.$refs.evalq.printMR();
			},
			showCheckin(item) {
				if (item && !item.progress.endoscopyCheckin) {
					this.checkinItem = item;
					this.pii = item.pii;
					this.checkin = true;
				}
			},
			async doCheckin() {
				if (!this.checkinItem) return;
				this.loading = true;
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'endoscopyCheckin',
							data: {
								id: this.checkinItem._id,
							}
						}
					});
					this.checkin = false;
					await this.fetchData();
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('到诊失败');
				}
				this.loading = false;
			},
			async printSticker(item) {
                item.age = calculateAge(item.pii.dob);
				item.insurancetxt = this.getInsurance(item);
				const res = await this.$dialog.prompt({
					title: '打印条码',
					text: '数量',
					rules: [(v) => parseInt(v) !== NaN || '请输入有效数字'],
					value: '1',
					textField: {
						// Any addtional props/attrs that will be binded to v-text-field component
						type: 'number',
						max: '20',
						min: '1'
					},
					width: 200,
					actions: {false:'取消', true:{text:'打印',color:'primary'}}
				});
				const n = parseInt(res);
				if (!n || n < 0 || n > 20) return;
				printSticker(item, n, err => {
					console.error(err);
					this.$dialog.error({title:'打印失败', text:err});
				});
			},
			update_w() {
				if (!this.endoscopyResult.pathology_k_w)
					this.endoscopyResult.pathology_k_w = (this.endoscopyResult.biopsy_w || 0) + (this.endoscopyResult.polyp_w || 0);
			},
			update_c() {
				if (!this.endoscopyResult.pathology_k_c)
					this.endoscopyResult.pathology_k_c = (this.endoscopyResult.biopsy_c || 0) + (this.endoscopyResult.polyp_c || 0);
			},
			async requestForm(type) {
				const item = this.selected[0];
				const rpt = item.reports?.[type+'镜'];
				if (!rpt) {
					this.$dialog.message.error('无法获取报告内容');
					return;
				}
				const pr = type === '胃' ? this.pathologyRequestW : this.pathologyRequestC;
				await this.$tcbapp.callFunction({name:"wp2mp",
					data:{
						funcname:'pathologyRequest',
						data: {
							id: item._id,
							type,
							requestForm: pr,
						}
					}
				});
				if (type === '胃') item.pathologyRequestW = this.pathologyRequestW;
				if (type === '肠') item.pathologyRequestC = this.pathologyRequestC;
				let sigimg;
				if (rpt.endoscopist) {
					const db = this.$tcbapp.database();
					const res = await db.collection('wp2doctor').where({username:rpt.endoscopist}).field({signature:true}).get();
					if (res.data.length > 0) {
						sigimg = await JSZipUtils.getBinaryContent(res.data[0].signature);
					} else {
						sigimg = await JSZipUtils.getBinaryContent('/none.png');
					}
				} else {
					sigimg = await JSZipUtils.getBinaryContent('/none.png');
				}
				const content = await JSZipUtils.getBinaryContent('/PathologyTemplate.docx');
				const imgOpts = {
					centered: false,
					fileType: "docx",
					getImage: function(tagValue, tagName) {
						return sigimg;
					},
					getSize: function(img, tagValue, tagName) {
						return tagValue === '' ? [1,1] : [70, 40];
					}
				};
				const doc = new Docxtemplater();
				const zip1 = new JSZip(content);
				const imageModule1 = new ImageModule(imgOpts);
				doc.attachModule(imageModule1).loadZip(zip1);
				doc.setOptions({
					paragraphLoop: true,
					linebreaks: true,
				});
				doc.setData({
					xm: item.pii.username,
					xb: item.pii.gender,
					nl: calculateAge(item.pii.dob),
					djh: item.pid,
					bgtxt: rpt.findings || '',
					zdjl: rpt.diagnoses || '',
					ysxm: item.endoscopyResult?.doctorT || '',
					sjrq: formatDate(item.progress.operative || new Date()),
					lcbl: pr?.mr || '',
					bw: type,
					js: pr?.quantity || '',
					jccl: pr?.samples || '',
					image: rpt.endoscopist || '',
				});
				doc.render();
				const blob = doc.getZip().generate({
					type: 'blob',
					mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
					compression: "DEFLATE",
				});
				const d = formatDate(item.progress.operative || new Date(), '');
				const filename = `${item.pid}_${d}-${item.pii.username} ${type}.docx`;
				saveAs(blob, filename);
				this.$dialog.message.success('保存完成');
			},
			async editNote(item) {
				await this.$dialog.showAndWait(PatientNote, {width:600, pid:item.pid});
				await this.fetchData();
			},
			async saveExpertAdvice() {
				if (!this.expertAdvice) {
					this.expertAdvice = '';
				}
                this.loading = true;
				const id = this.selected[0]._id;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'saveExpertAdvice', data:{id, expertAdvice:this.expertAdvice}}});
					this.selected[0].expertAdvice = this.expertAdvice;
					this.$dialog.message.success('保存完成');
				} catch(err) {
					console.error(err);				
					this.$dialog.message.error('保存失败');
				}
				this.loading = false;
			},
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
				this.endoscopyResult = newitem[0].endoscopyResult || this.$options.data().endoscopyResult;
				this.pathologyAgency = newitem[0].pathologyAgency;
				if (newitem[0].history) {
					this.historyItems = Object.assign(newitem[0].history);
				} else {
					this.historyItems = [];
				}
				const s1 = 'cloud://huasan-test-id.6875-huasan-test-id-1300427821';
				const s2 = 'https://6875-huasan-test-id-1300427821.tcb.qcloud.la';
				const s3 = 'cloud://huasan-6gaadaha7ceb62a9.6875-huasan-6gaadaha7ceb62a9-1300427821';
				const s4 = 'https://6875-huasan-6gaadaha7ceb62a9-1300427821.tcb.qcloud.la';
				this.eeresults = (newitem[0].eeresults || []).map(x => x.replace(s1, s2).replace(s3, s4));
				if (newitem[0].chargeQuotation) {
					const q = this.quotations.find(x => x.name === newitem[0].chargeQuotation);
					if (q) {
						this.quotation = q;
						if (newitem[0].charges) {
							this.charges = this.quotation.treatment.map(x => newitem[0].charges.find(y => y.id === x.id) || {id:x.id, quantity:null});
						} else {
							this.charges = this.quotation.treatment.map(x => ({id:x.id, quantity:null}));
						}
					}
				} else {
					//保险直付的用“常规”，其他用“Q2024”
					if (newitem[0].payment.insurance === '直付') {
						this.quotation = this.quotations[1];
					} else {
						this.quotation = this.quotations[0];
					}
					this.charges = this.quotation.treatment.map(x => ({id:x.id, quantity:null}));
				}
				if (newitem[0].subsequent) {
					this.periodw = newitem[0].subsequent.periodw;
					this.periodc = newitem[0].subsequent.periodc;
				} else {
					this.periodw = null;
					this.periodc = null;
				}
				this.pathologyRequestW = newitem[0].pathologyRequestW || this.$options.data().pathologyRequestW;
				this.pathologyRequestC = newitem[0].pathologyRequestC || this.$options.data().pathologyRequestC;
				this.expertAdvice = newitem[0].expertAdvice;
				this.fetchMr();
			},
			pdate1(newv, oldv) {
				if (newv && this.pdate2) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 > t2) {
						this.pdate2 = newv;
					}
				}
				this.fetchData();
			},
			pdate2(newv, oldv) {
				if (newv && this.pdate1) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 > t2) {
						this.pdate1 = newv;
					}
				}
				this.fetchData();
			},
			'endoscopyResult.biopsy_w': 'update_w',
			'endoscopyResult.polyp_w': 'update_w',
			'endoscopyResult.biopsy_c': 'update_c',
			'endoscopyResult.polyp_c': 'update_c',
			'endoscopyResult.pathology_k_w': function(newv) {
				if (newv) {
					this.periodw = null;
				}
			},
			'endoscopyResult.pathology_k_c': function(newv) {
				if (newv) {
					this.periodc = null;
				}
			},
		},
        components: { Splitpanes, Pane, DiagnoseHistory, Diagnose, EvalQ }
    }
</script>

<style>
.splitpanes.default-theme .splitpanes__pane {
    background-color: white;
}
.splitpanes.default-theme .splitpanes__splitter {
    background-color: #eee;
}
.splitpanes.default-theme .splitpanes__splitter:after,
.splitpanes.default-theme .splitpanes__splitter:before {
    background-color: rgba(0, 0, 0, .25);
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter,
.default-theme .splitpanes--horizontal>.splitpanes__splitter {
    height: 8px;
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme.splitpanes--horizontal>.splitpanes__splitter:before,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:before {
    width: 60px;
    height: 1.5px
}
.v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-left: 16px;
}
.bcicon {
	padding: 0!important;
}
.edres {
	max-width:64px;
	display:inline-block;
}
</style>

<style scoped>
.edres >>> input {
	text-align: right;
}
</style>
