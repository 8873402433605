<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)">
			<pane min-size="10" size="35">
			<div style="max-height: 100%" class="overflow-y-auto">
				<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
					:options.sync="options" :server-items-length="totalItems"
					:loading="loading" loading-text="加载中..." show-select single-select>
				<template v-slot:top>
					<div class="d-flex flex-wrap pt-3 pb-1 pl-1" style="gap:16px">
						<v-btn-toggle mandatory dense v-model="filter" @change="fetchData">
							<v-btn>未计费</v-btn>
							<v-btn>已计费未结算</v-btn>
							<v-btn>已结算</v-btn>
						</v-btn-toggle>
						<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="pdate1" label="预约手术日期起" readonly v-bind="attrs"
									v-on="on" dense hide-details clearable style="flex: 0 1 auto;max-width:150px;"></v-text-field>
							</template>
							<v-date-picker v-model="pdate1" @input="menu1 = false" no-title scrollable></v-date-picker>
						</v-menu>
						<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="pdate2" label="预约手术日期止" readonly v-bind="attrs"
									v-on="on" dense hide-details clearable style="flex: 0 1 auto;max-width:150px;"></v-text-field>
							</template>
							<v-date-picker v-model="pdate2" @input="menu2 = false" no-title scrollable></v-date-picker>
						</v-menu>
						<v-text-field v-model="pname" label="客户姓名" clearable dense hide-details style="flex: 0 1 auto;max-width:150px;"></v-text-field>
						<v-btn color="primary" @click.stop="fetchData">查询</v-btn>
					</div>
					<v-divider/>
				</template>
				<template v-slot:item.insurance="{ item }">
					<span :class="item.payment.insurance ? 'teal--text text--accent-4 font-weight-bold' : 'black--text'">{{getInsurance(item)}}</span>
				</template>
				<template v-slot:item.apntOprtDate="{ item }">
					{{formatTime(item.apntOprtDate)}}
				</template>
				<template v-slot:item.progress.operative="{ item }">
					{{formatTime(item.progress.operative)}}
				</template>
				<template v-slot:item.progress.charging="{ item }">
					{{formatTime(item.progress.charging)}}
				</template>
				<template v-slot:item.progress.billing="{ item }">
					{{formatTime(item.progress.billing)}}
				</template>
				<template v-slot:item.opts="{ item }">
					{{getOptsStr(item)}}
				</template>
				</v-data-table>
			</div>
			</pane>
			<pane min-size="20" size="65" v-if="selected.length > 0 && !!selected[0].charges">
				<div style="max-height: 100%" class="d-flex overflow-y-auto">
					<div class="pt-2 pl-2">
						<div class="my-2">
							<v-text-field outlined readonly dense hide-details label="计费模板" :value="quotation.name" style="max-width:150px"/>
						</div>
						<v-simple-table id="chargestab">
							<template v-slot:default>
							<thead style="background-color: #eee">
								<tr>
									<th>诊疗项目</th>
									<th>计价说明</th>
									<th>数量</th>
									<th>合计</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="(item, index) in quotation.treatment">
								<tr v-if="item.enabled || (!!charges[index] && !!charges[index].quantity)">
									<td width="44%">{{ item.subject }}</td>
									<td width="40%">{{ item.description }}</td>
									<td width="8%">{{ charges[index].quantity }}</td>
									<td width="8%">{{ charges[index].amount }}</td>
								</tr>
								</template>
							</tbody>
							</template>
						</v-simple-table>
						<v-row class="pt-4">
							<v-col><v-text-field label="诊疗合计" :value="selected[0].chargeSum" hide-details readonly/></v-col>
							<v-col><v-select label="折扣" :items="discountList" v-model="discount" hide-details/></v-col>
							<v-col><v-text-field label="诊疗应收" :value="valueAmount" hide-details readonly/></v-col>
						</v-row>
						<v-row>
							<v-col><v-text-field label="其他" v-model.number="other" hide-details/></v-col>
							<v-col/>
							<v-col><v-text-field label="其他应收" :value="other" hide-details readonly/></v-col>
						</v-row>
						<v-row class="pt-4" style="border-top:3px double #ccc">
							<v-col/>
							<v-col/>
							<v-col><v-text-field label="应收合计" :value="valueAmount + other" hide-details readonly/></v-col>
						</v-row>
						<div class="pt-6 pb-4" style="text-align:right">
							<v-btn class="primary" style="width:120px" :loading="loading" :disabled="loading||!canEdit" @click.stop="save">结算并保存</v-btn>
							<v-btn class="primary ml-4" style="width:120px" :disabled="!selected[0].chargeBill" @click.stop="view">查看结算单</v-btn>
						</div>
					</div>
				</div>
			</pane>
		</splitpanes>
		<bill v-model="billDlg" :plan="plan" :quotation="quotation.treatment"/>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import {formatTime, formatDate} from '../utils'
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'
	import store from '../store.js'
	import Bill from '../components/Bill.vue'

    export default {
        data() {
            return {
                authed: false,
                headers: [
                    {text:'病案号', value:'pid', width:100},
                    {text:'姓名', value:'pii.username', width:120},
                    {text:'证件号', value:'pii.idcard', width:120},
                    {text:'支付方', value:'insurance', width:150},
                    {text:'术中费用', value:'opts.CHARGE', width:120},
                    {text:'预约手术时间', value:'apntOprtDate', width:180},
                    {text:'手术完成时间', value:'progress.operative', width:180},
                    {text:'手术医生', value:'endoscopyResult.doctorT', width:100},
                    {text:'计费时间', value:'progress.charging', width:180},
                    {text:'计费人', value:'operator.charging', width:100},
                    {text:'结算金额', value:'chargeBill.total', width:100},
                    {text:'结算时间', value:'progress.billing', width:180},
                    {text:'结算人', value:'operator.billing', width:100},
                    {text:'套餐内容', value:'opts', width:240, sortable: false},
//                    {text:'已选专家', value:'doctorName', width:100},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'订单号', value:'_id', width:100},
                ],
                items: [],
                loading: false,
				selected: [],
				filter: 0,
				options: {sortBy:['apntOprtDate'], sortDesc:[false]},
				totalItems: 0,
				quotations: [],
				quotation: {},
                charges: [],
				discountList: [
					{text:'100%', value:1.0},
					{text:'80%', value:0.8},
					{text:'60%', value:0.6},
					{text:'50%', value:0.5},
					{text:'0%', value:0}
				],
				discount: 1.0,
				other: null,
				billDlg: false,
				plan: null,
				pdate1: '',
				menu1: false,
				pdate2: '',
				menu2: false,
				pname: '',
				canEdit: false,
            }
        },
        mounted() {
			this.authed = this.$hasPrivilege(['术中结算','术中结算-只读','健康顾问']);
			if (!this.authed) return;
			this.canEdit = this.$hasPrivilege('术中结算');
			this.formatTime = formatTime;
			this.pdate1 = formatDate(Date.now());
			this.pdate2 = formatDate(Date.now());
			this.$watch(()=>store.currentRegion, this.fetchData);
			this.fetchQuotation();
			this.fetchData();
        },
        methods: {
            getOptsStr(item) {
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO + (item.opts.TX ? '/' + item.opts.TX : '');
			},
			getInsurance(item) {
				return item.payment.insurance && item.payment.insurer ? `${item.payment.insurer} - ${item.payment.insurance}` : '自费';
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				const filters = [
					{'progress.charging':_.exists(false)},
					{'progress.charging':_.exists(true), 'endoscopyResult':_.exists(true), 'chargeBill':_.exists(false)},
					{'chargeBill':_.exists(true)},
				];
				const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
				const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
				let filter = [];
				if (this.$hasPrivilege(['术中结算','术中结算-只读'])) {
					//admin也有此权限
				} else if (this.$hasPrivilege('健康顾问')) {
					const u = this.$getCurrentUser();
					this.consultantId = u.consultantId;
					filter.push({consultantId: u.consultantId});
				}
				if (t1 && t2) {
					filter.push({apntOprtDate:_.gt(t1).lt(t2)});
				} else if (this.pdate1) {
					filter.push({apntOprtDate:_.gt(t1)});
				} else if (this.pdate2) {
					filter.push({apntOprtDate:_.lt(t2)});
				} else {
					filter.push({apntOprtDate:_.exists(true)});
				}
				if (this.pname) {
					filter.push({'pii.username':this.pname});
				}
				filter.push({hide:_.neq(true)});
				filter.push({region:store.currentRegion});
				const f = filter.concat(filters[this.filter]);
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			async fetchQuotation() {
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp", data:{
						funcname:'getTreatmentQuotation',
						data: {}
					}});
					this.quotations = res.result;
					this.quotation = this.quotations[0];
				} catch(err) {
					console.error(err);				
				}
			},
			async save() {
                this.loading = true;
				const id = this.selected[0]._id;
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp", data:{
						funcname:'billing',
						data: {
							id,
							discount: this.discount,
							other: this.other,
						}
					}});
					this.plan = Object.assign(this.selected[0], res.result);
					this.plan.progress.billing = new Date();
					//this.plan = {...this.selected[0], ...res.result};
					await this.fetchData();
					this.billDlg = true;
				} catch(err) {
					console.error(err);				
					this.$dialog.message.error('保存失败');
				}
				this.loading = false;
			},
			view() {
				this.billDlg = true;
			},
		},
		watch: {
			pdate1(newv, oldv) {
				if (newv && this.pdate2) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 > t2) {
						this.pdate2 = newv;
					}
				}
				this.fetchData();
			},
			pdate2(newv, oldv) {
				if (newv && this.pdate1) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 > t2) {
						this.pdate1 = newv;
					}
				}
				this.fetchData();
			},
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) {
					this.plan = null;
					return;
				}
				this.plan = newitem[0];
				this.charges = [];
				if (newitem[0].chargeQuotation && newitem[0].charges) {
					const q = this.quotations.find(x => x.name === newitem[0].chargeQuotation);
					if (q) {
						this.quotation = q;
						this.charges = q.treatment.map(x => newitem[0].charges.find(y => y.id === x.id) || {id:x.id, quantity:null});
					}
				}
				if (newitem[0].chargeBill) {
					this.discount = newitem[0].chargeBill.discount;
					this.other = newitem[0].chargeBill.other;
				} else {
					this.discount = 1.0;
					this.other = null;
				}
				const sum = this.charges.reduce((a, v) => a + (v.amount || 0), 0);
				newitem[0].chargeSum = sum;
			}
		},
		computed: {
			valueAmount() {
				if (this.selected.length === 0) return null;
				return Math.round(this.selected[0].chargeSum * this.discount);
			}
		},
        components: { Splitpanes, Pane, Bill }
    }
</script>

<style>
.splitpanes.default-theme .splitpanes__pane {
    background-color: white;
}
.splitpanes.default-theme .splitpanes__splitter {
    background-color: #eee;
}
.splitpanes.default-theme .splitpanes__splitter:after,
.splitpanes.default-theme .splitpanes__splitter:before {
    background-color: rgba(0, 0, 0, .25);
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter,
.default-theme .splitpanes--horizontal>.splitpanes__splitter {
    height: 8px;
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme.splitpanes--horizontal>.splitpanes__splitter:before,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:before {
    width: 60px;
    height: 1.5px
}
.v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-left: 16px;
}
#chargestab table { border:1px solid #dddddd; width:750px;}
#chargestab table th {height:32px;line-height:1.0;}
#chargestab table td {height:32px;line-height:1.0;}
#chargestab table th + th { border-left:1px solid #dddddd; }
#chargestab table td + td { border-left:1px solid #dddddd; }
</style>