<template>
	<v-dialog persistent v-model="dialog" width="800px">
		<v-card :loading="loading" v-if="!!plan">
			<v-card-text id="printme" style="padding:50px">
				<!--<img src="/common/huasan_hb.png" height="60" style="position:absolute;"/>-->
				<img src='/huasanlogo1.png' height="60" style="position:absolute;"/>
				<span class="billtitle">北京华三诊所收费单</span>
				<div style="display:flex;justify-content:space-between;margin-top:24px;font-size:12px;" >
					<div style="flex:0 0 auto;"><span>姓名</span><span class="valuecontent">{{plan.pii.username}}</span></div>
					<div style="flex:0 0 auto;"><span>病案号</span><span class="valuecontent">{{plan.pid}}</span></div>
				</div>
				<v-simple-table style="margin-top:8px;">
					<template v-slot:default>
					<thead style="background-color: #eee">
						<tr>
							<th>诊疗项目</th>
							<th>计价说明</th>
							<th>数量</th>
							<th>合计</th>
						</tr>
					</thead>
					<tbody>
						<template v-for="(item, index) in quotation">
						<tr v-if="!!charges[index].quantity">
							<td width="44%">{{ item.subject }}</td>
							<td width="34%">{{ item.description }}</td>
							<td width="10%">{{ charges[index].quantity || '-' }}</td>
							<td width="12%" style="text-align:right">{{ charges[index].amount ? charges[index].amount.toFixed(2) : '-' }}</td>
						</tr>
						</template>
					</tbody>
					</template>
				</v-simple-table>
				<v-container style="margin-top:8px;font-size:12px;" v-if="!!plan.chargeBill">
					<v-row>
						<v-col><span>诊疗合计</span><span class="valuecontent">{{plan.chargeBill.chargeSum.toFixed(2)}}</span></v-col>
						<v-col><span>折扣</span><span class="valuecontent">{{parseFloat(plan.chargeBill.discount)*100 + '%'}}</span></v-col>
						<v-col><span>诊疗应收</span><span class="valuecontent">{{(plan.chargeBill.chargeSum * plan.chargeBill.discount).toFixed(2)}}</span></v-col>
					</v-row>
					<v-row>
						<v-col><span>其他费用</span><span class="valuecontent">{{plan.chargeBill.other ? parseFloat(plan.chargeBill.other).toFixed(2) : '无'}}</span></v-col>
						<v-col/>
						<v-col><span>其他应收</span><span class="valuecontent">{{plan.chargeBill.other ? parseFloat(plan.chargeBill.other).toFixed(2) : '无'}}</span></v-col>
					</v-row>
					<v-row style="border-top:3px double #ccc;">
						<v-col/>
						<v-col/>
						<v-col><span>应收合计</span><span class="valuecontent">{{parseFloat(plan.chargeBill.total).toFixed(2)}}</span></v-col>
					</v-row>
					<v-row style="margin-top:32px;">
						<!--<v-col><span>护士签字</span><span class="valuecontent">{{plan.operator.charging}}</span></v-col>
						<v-col><span>前台签字</span><span class="valuecontent">{{plan.operator.billing}}</span></v-col>-->
						<v-col></v-col>
						<v-col></v-col>
						<!--<v-col><span>前台签字</span><span class="valuecontent"></span></v-col>-->
						<v-col><span>手术日期</span><span class="valuecontent">{{formatDate(plan.progress.operative)}}</span></v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer/>
				<v-btn color="primary" v-print="'#printme'">打印</v-btn>
				<v-btn @click.stop="exitDlg">返回</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import print from 'vue-print-nb'
    import {formatDate} from '../utils'

	export default {
		directives: {
			print   
		},
		props: {
			value: Boolean,
			quotation: Array,
			plan: Object,
		},
		data() {
			return {
				loading: false,
                charges: [],
			}
		},
		computed: {
			dialog: {
				get() {
					return this.value;
				},
				set(v) {
					if (!v) {
						this.$emit('input', false);
					}
				}
			}
		},
		watch: {
			value(v) {
				if (v) {
					if (this.plan && this.plan.charges) {
						this.charges = this.quotation.map(x => this.plan.charges.find(y => y.id === x.id) || {id:x.id, quantity:null, amount:null});
					} else {
						this.charges = [];
					}
				}
			}
		},
		created() {
			this.formatDate = formatDate;
		},
		methods: {
			exitDlg() {
				this.dialog = false;
				this.$emit('close');
			},
        }
    }
</script>

<style>
#printme table { border:2px solid #dddddd; }
#printme table th {height:32px;line-height:1.0;}
#printme table td {height:32px;line-height:1.0;font-size:12px;}
#printme table th + th { border-left:1px solid #dddddd; }
#printme table td + td { border-left:1px solid #dddddd; }
.valuecontent {
	display:inline-block;
	width:120px;
	border-bottom: 1px solid black;
	text-align:center;
	font-size:12px;
}
.valuecontent:empty:after {	/* 保持空白的高度 */
	content: '\200b';
}
.billtitle {
	display:inline-block;
	width:100%;
	height:60px;
	line-height:70px;
	text-align:center;
	font-size:20px;
	font-weight:bold;
}
</style>
