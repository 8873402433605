<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)">
			<pane min-size="10" size="35">
				<div style="max-height: 100%" class="overflow-y-auto">
					<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
						:options.sync="options" :server-items-length="totalItems"
						:loading="loading" loading-text="加载中..." show-select single-select @input="setCurrentDate">
						<template v-slot:top>
							<div class="d-flex flex-wrap">
								<v-btn-toggle class="d-flex flex-wrap mr-4" mandatory dense v-model="filter1" @change="fetchData">
									<v-btn>全部</v-btn>
									<v-btn>待约采血</v-btn>
									<v-btn>待约评估</v-btn>
									<v-btn>待约手术</v-btn>
									<v-btn>待约解读</v-btn>
									<v-btn>待采血</v-btn>
									<v-btn>待评估</v-btn>
									<v-btn>待手术</v-btn>
									<v-btn>待解读</v-btn>
									<v-btn>门诊</v-btn>
								</v-btn-toggle>
								<v-text-field placeholder="姓名" v-model="pname" dense clearable hide-details style="flex: 0 1 auto" class="ml-4"
									append-icon="search" @click:append="fetchData" @keyup.enter.native="fetchData"/>
							</div>
							<v-divider/>
						</template>
						<template v-slot:item.apntBldDate="{ item }">
							{{formatTime(item.apntBldDate)}}
						</template>
						<template v-slot:item.apntEvalDate="{ item }">
							{{formatTime(item.apntEvalDate)}}
						</template>
						<template v-slot:item.apntOprtDate="{ item }">
							{{formatTime(item.apntOprtDate)}}
						</template>
						<template v-slot:item.apntPthlDate="{ item }">
							{{formatTime(item.apntPthlDate)}}
						</template>
						<template v-slot:item.apntClncDate="{ item }">
							{{formatTime(item.apntClncDate)}}
						</template>
						<template v-slot:item.insurance="{ item }">
							<span :class="item.payment && item.payment.insurance ? 'teal--text text--accent-4 font-weight-bold' : 'black--text'">{{getInsurance(item)}}</span>
						</template>
						<template v-slot:item.orderSource="{ item }">
							<span :class="item.orderSource === '定向' ? 'purple--text font-weight-bold' : 'black--text'">{{item.orderSource}}</span>
						</template>
						<template v-slot:item.idcard="{ item }">
							{{getMaskedIdcard(item)}}
						</template>
						<template v-slot:item.age="{ item }">
							{{getAge(item)}}
						</template>
						<template v-slot:item.opts="{ item }">
							{{getOptsStr(item)}}
						</template>
						<template v-slot:item.evalConclusion="{ item }">
							{{getEvalConclusion(item)}}
						</template>
					</v-data-table>
				</div>
			</pane>
			<pane min-size="20" size="65" v-if="(selected.length > 0)">
				<div style="height:100%;" class="overflow-y-auto pl-4">
					<Calendar2 style="width:98%" ref="calendar"
						:permissions="permissions"
						:showdialog="onShowDialog"
						:currentdate="currentdate"
						:orderid="selected[0] ? selected[0]._id : ''"
						:attach="attach"
						@aftermake="fetchData"
						@aftercancel="fetchData">
						<template v-slot:details>
							<v-text-field label="姓名" readonly v-model="cname" dense hide-details/>
						</template>
						<template v-slot:tag.p2>
							<v-radio-group mandatory dense hide-details label="评估科室" v-model="p2detail">
								<v-radio label="内科" value="内科"/>
								<v-radio label="麻醉" value="麻醉"/>
								<v-radio label="内科+麻醉" value="内科+麻醉"/>
							</v-radio-group>
						</template>
					</Calendar2>
	            </div>
			</pane>
		</splitpanes>
	    <v-snackbar color="error" v-model="snackbar">
			{{errmsg}}
			<template v-slot:action="{ attrs }">
				<v-btn outlined small v-bind="attrs" @click="snackbar = false">关闭</v-btn>
			</template>
		</v-snackbar>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import {formatTime, formatDate, optstr, calculateAge, nowOffsetHours, today, offsetDayTime} from '../utils'
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'
	import Calendar2 from '@/components/Calendar2.vue'
	import store from '../store.js'

	const prevDate = (s) => formatDate(Date.parse(s) - 86400 * 1000);

    export default {
		name: 'Appointment',
		props:['phase'],
        data() {
            return {
                authed: false,
				apntdeadline: {},
				permissions: [],
                headers: [
                    {text:'病案号', value:'pid', width:100},
                    {text:'姓名', value:'pii.username', width:120},
                    {text:'支付方', value:'insurance', width:150},
                    {text:'优惠选项', value:'payment.coupontext', width:100},
                    {text:'渠道', value:'orderSource', width:80},
                    {text:'预约采血时间', value:'apntBldDate', width:180},
                    {text:'预约评估时间', value:'apntEvalDate', width:180},
                    {text:'预约手术时间', value:'apntOprtDate', width:180},
                    {text:'预约解读时间', value:'apntPthlDate', width:180},
                    {text:'套餐内容', value:'opts', width:240, sortable: false},
                    {text:'术前', value:'opts.PRE', width:90},
                    {text:'术中费用', value:'opts.CHARGE', width:120},
                    {text:'已选专家', value:'doctorName', width:100},
                    {text:'电话', value:'pii.phone', width:100},
                    {text:'证件类型', value:'pii.idtype', width:100},
                    {text:'证件号', value:'idcard', width:120},
                    {text:'性别', value:'pii.gender', width:80},
                    {text:'身高', value:'QA.baseinfo.bodyheight', width:80},
                    {text:'体重', value:'QA.baseinfo.bodyweight', width:80},
                    {text:'年龄', value:'age', width:80},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'评估结论', value:'evalConclusion', width:180},
                    {text:'付款备注', value:'payment.comment', width:200},
                    {text:'订单号', value:'_id', width:100},
                    {text:'订单类型', value:'orderType', width:120},
                    {text:'诊所', value:'region', width:100},
                    {text:'预约门诊时间', value:'apntClncDate', width:180},
                ],
                items: [],
                loading: false,
				selected: [],
				customerId: '',
				cname: '',
				viewAll: false,
				consultantId: '',
				evalway: '',
				evalcons1: '',
				evalcons2: '',
				gastroPrompt1: false,
				gastroPrompt2: false,
				gastroPrompt3: false,
				gastroPrompt4: false,
				showevalcons: false,
				interpretway: '',
				showinterpret: false,
				currentdate: formatDate(Date.now() + nowOffsetHours * 3600 * 1000),
				snackbar: false,
				errmsg: '',
				filter1: 0,
				options: {},
				totalItems: 0,
				pname: '',
				p2detail: '',
				attach: {
					p2: () => ({apntEvalDetail:{subject:this.p2detail}}),
				}
            }
        },
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
				this.cname = newitem[0].pii.username;
			}
		},
		created() {
			const workset = JSON.parse(window.sessionStorage.getItem('workset'));
			this.apntdeadline = workset.apntdeadline;
		},
        mounted() {
			this.authed = this.$hasPrivilege('预约接待') || this.$hasPrivilege('健康顾问');
			if (!this.authed) return;
			this.permissions = ['t','bt','p','p2','z','jtz','c','bld'];
			this.viewAll = this.$hasPrivilege('预约接待');
			this.formatTime = formatTime;
			this.$watch(()=>store.currentRegion, this.fetchData);
			this.fetchData();
		},
        methods: {
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [];
				if (this.$hasPrivilege('预约接待')) {
					//admin也有此权限
				} else if (this.$hasPrivilege('健康顾问')) {
					const u = this.$getCurrentUser();
					this.consultantId = u.consultantId;
					filter.push({consultantId: u.consultantId});
				}
				if (this.pname) {
					filter.push({'pii.username':this.pname});
				}
				filter.push({hide:_.neq(true)});
				filter.push({region:store.currentRegion});
				let f;
				if (this.filter1 === 9) {
//					f = filter.concat([{category:'clinic'}, {apntClncDate:_.exists(false)}]);
					f = filter.concat([{category:'clinic'}]);
				} else {
					filter.push(_.and([
						{"payment.actual":_.exists(true)},
						{'endoscopyResult.pathology' : _.neq('无')}, {'progress.pathology' : _.exists(false)}	//未完成的订单
					]));
					const filters1 = [
						[],
						[{apntBldDate:_.exists(false)}, {'opts.PRE':'华三'}],
						[{apntEvalDate:_.exists(false)}, _.or([{eeaccepted:_.exists(true)}, {'payment.insurer':_.exists(true)}])],
						[{apntOprtDate:_.exists(false)}, _.or([{eeaccepted:_.exists(true)}, {'payment.insurer':_.exists(true)}, {orderSource:'定向'}])],
						[{apntPthlDate:_.exists(false)}, {'endoscopyResult.pathology':'有'}, {'progress.pathologyReturn':_.exists(true)}],
						[{apntBldDate:_.exists(true)}, {eeaccepted:_.exists(false)}],
						[{apntEvalDate:_.exists(true)}, _.and([{'progress.evalConclusion':_.exists(false)}, {'progress.diagnosed':_.exists(false)}])],
						[{apntOprtDate:_.exists(true)}, {'progress.operative':_.exists(false)}],
						[{apntPthlDate:_.exists(true)}, {'progress.pathology':_.exists(false)}]
					];
					f = filter.concat(filters1[this.filter1]);
				}
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
					if (this.selected.length > 0) {
						const s = this.items.find(x => x._id === this.selected[0]._id);
						this.selected = s ? [s] : [];
					}
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			getInsurance(item) {
				if (!item.payment) return '';
				return item.payment.insurance && item.payment.insurer ? `${item.payment.insurer} - ${item.payment.insurance}` : '自费';
			},
			getMaskedIdcard(item) {
				return item.pii.idcard;
			},
            getAge(item) {
                return calculateAge(item.pii.dob, item.progress.order);
            },
            getOptsStr(item) {
				if (!item.opts) return '';
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO + (item.opts.TX ? '/' + item.opts.TX : '');
			},
			getEvalConclusion(item) {
				if (!item.evalConclusion) return '';
				const ec = item.evalConclusion;
				const r = [ec.operationQueue];
				if (ec.gastroPrompt1) r.push('7天停抗凝药');
				if (ec.gastroPrompt2) r.push('T日停降糖药');
				if (ec.gastroPrompt3) r.push('4包清肠药');
				if (ec.gastroPrompt4) r.push('T日服降压药');
				if (ec.notice) r.push(ec.notice);
				return r.join('/');
			},
			//2024.11.26 张捷 bt/zt的取消和预约都改成T-2 16:00
			testDeadlineMake(tag, date) {
				const dl = this.apntdeadline[tag];
				return dl && (Date.now() < offsetDayTime(date, dl[0], dl[1]));
			},
			testDeadlineCancel(tag, date) {
				const dl = this.apntdeadline[tag];
				return dl && (Date.now() < offsetDayTime(date, dl[2], dl[3]));
			},
			onShowDialog(date, event/*, queue*/) {
				const supv = this.$hasPrivilege('预约接待');
				const now = Date.now();
				const item = this.selected[0];
				if (event.info) {		//有info则显示取消预约
					if (!supv) {
						switch(event.tag) {
							case 'p':
							case 'p2':
								if (item.apntOprtDate) {
									this.errmsg = '请先取消手术预约再取消评估预约';
									this.snackbar = true;
									return false;
								}
							/*
							case 'z':
							case 'bld':
							case 'c':
								if (now > offsetDayTime(date, -1, 16)) {
									this.errmsg = '已过可取消日期';
									this.snackbar = true;
									return false;
								}
								break;
							case 't':
							case 'bt':
							case 'jtz':
								if (now > offsetDayTime(date, -2, 15)) {
									this.errmsg = '已过可取消日期';
									this.snackbar = true;
									return false;
								}
								break;
							*/
						}
						if (!this.testDeadlineCancel(event.tag, date)) {
							this.errmsg = '已过可取消截止时间';
							this.snackbar = true;
							return false;
						}
					}
					return event.orderid === item._id;
				}
				if (!supv && !this.testDeadlineMake(event.tag, date)) {
					this.errmsg = '已过可预约截止时间';
					this.snackbar = true;
					return false;
				}
				//const firstAvailable = queue.find(x => !x.info);
				const firstAvailable = this.$refs.calendar.findFirstAvailable(date, event.tag, event.queue);
				if (!supv && (firstAvailable !== event) && (event.tag !== 'p')) {
					this.errmsg = '请按时间顺序预约';
					this.snackbar = true;
					return false;
				}
				switch(event.tag) {
					case 'p':
						if (item.apntEvalDate) {
							this.errmsg = '已预约' + formatTime(item.apntEvalDate);
							this.snackbar = true;
							return false;
						}
						if (!item.eeaccepted && !item.payment.insurer) {
							this.errmsg = '术前检查单未收讫';
							this.snackbar = true;
							return false;
						}
						if (!supv) {
							//2022.2.24 耿晓晨要求调到18
							//2023.4.18 李奇云要求调到16
							if (now > offsetDayTime(date, -1, 16)) {
								this.errmsg = '已过可预约时间';
								this.snackbar = true;
								return false;
							}
							if (item.progress.eeaccepted) {
								const dt = (now - item.progress.eeaccepted.valueOf()) / (1000 * 86400);
								if (dt > 30) {
									this.errmsg = '术前结果已超过30天，请联系医助';
									this.snackbar = true;
									return false;
								}
							}
						}
						/* 2023.11.29 李奇云要求取消BMI限制
						const h = item.QA.baseinfo.bodyheight * 0.01;
						const bmi = item.QA.baseinfo.bodyweight / (h * h);
						if (bmi > 30) {
								this.errmsg = 'BMI超过30，请预约到院评估';
								this.snackbar = true;
								return false;
						}
						*/
//						this.evalway = '视频评估';
						return true;
					case 'p2':
						if (item.apntEvalDate) {
							this.errmsg = '已预约' + formatTime(item.apntEvalDate);
							this.snackbar = true;
							return false;
						}
						if (!item.payment || !item.payment.insurance) {
							this.errmsg = '非保险客户不能预约到院评估';
							this.snackbar = true;
							return false;
						}
						/* 2023.7.11 张磊/张捷 确认不要求面评先收讫
						if (!item.eeaccepted && !item.payment.insurer) {
							this.errmsg = '术前检查单未收讫';
							this.snackbar = true;
							return false;
						}
						*/
						if (!supv) {
							if (now > offsetDayTime(date, -1, 16)) {
								this.errmsg = '已过可预约时间';
								this.snackbar = true;
								return false;
							}
							if (item.progress.eeaccepted) {
								const dt = (now - item.progress.eeaccepted.valueOf()) / (1000 * 86400);
								if (dt > 30) {
									this.errmsg = '术前结果已超过30天，请联系医助';
									this.snackbar = true;
									return false;
								}
							}
						}
//						this.evalway = '到院评估';
						return true;
					case 't':
					case 'bt':
					case 'jtz':
						if (item.apntOprtDate) {
							this.errmsg = '已预约' + formatTime(item.apntOprtDate);
							this.snackbar = true;
							return false;
						}
						if (!supv) {
							if (!item.apntEvalDate) {
								this.errmsg = '请先预约评估再预约手术';
								this.snackbar = true;
								return false;
							}
							if (item.progress.evalConclusion) {
								const dt = (now - item.progress.evalConclusion.valueOf()) / (1000 * 86400);
								if (dt > 30) {
									this.errmsg = '评估完成已超过30天，请联系医助';
									this.snackbar = true;
									return false;
								}
							}
						}
						if ((item.orderSource !== '定向') && !supv && now > offsetDayTime(date, -2, 15)) {
							this.errmsg = '已过可预约时间';
							this.snackbar = true;
							return false;
						}
						if (event.tag === 't' && item.opts.PRO !== '专家精查') {
							this.errmsg = '产品非ZT';
							this.snackbar = true;
							return false;
						} else if (event.tag === 'bt' && (item.opts.PRO !== '标准精查' && item.opts.PRO !== '健康精查T')) {
							this.errmsg = '产品非BT或JT-T';
							this.snackbar = true;
							return false;
						} else if (event.tag === 'jtz' &&  item.opts.PRO !== '健康精查Z') {
							this.errmsg = '产品非JT-Z';
							this.snackbar = true;
							return false;
						}
						return true;
					case 'z':
						if (item.apntPthlDate) {
							this.errmsg = '已预约' + formatTime(item.apntPthlDate);
							this.snackbar = true;
							return false;
						}
						if (!supv && now > offsetDayTime(date, -1, 16)) {
							this.errmsg = '已过可预约日期';
							this.snackbar = true;
							return false;
						}
						if (!item.endoscopyResult) {
							this.errmsg = '尚未手术';
							this.snackbar = true;
							return false;
						}
						if (item.endoscopyResult.pathology !== '有') {
							this.errmsg = '无病理';
							this.snackbar = true;
							return false;
						}
						if (!item.progress.pathologyReturn) {
							this.errmsg = '病理报告尚未返回';
							this.snackbar = true;
							return false;
						}
						return true;
					case 'bld':
						if (item.apntBldDate) {
							this.errmsg = '已预约' + formatTime(item.apntBldDate);
							this.snackbar = true;
							return false;
						}
						if (!supv && now > offsetDayTime(date, -1, 16)) {
							this.errmsg = '已过可预约日期';
							this.snackbar = true;
							return false;
						}
						return true;
					case 'c':
						if (!supv) {
							if (now > offsetDayTime(date, -1, 16)) {
								this.errmsg = '已过可预约时间';
								this.snackbar = true;
								return false;
							}
						}
						return true;
				}
				return false;
			},
			/*
			async postNote(data) {
				data.customerId = this.selected[0]._id;
				await this.$tcbapp.callFunction({
					name: "postNote",
					data
				})
			},
			beforemake(date, a, b) {
				const c = this.selected[0];
				a.consultantId = c.consultantId;
				a.customerId = c._id;
				a.name = c.pii.username;
				a.gender = c.pii.gender;
				a.age = this.getAge(c);
				switch(a.tag) {
					case 'p':
					case 'p2':
						a.params = { evalway: this.evalway };
						b.evalway = this.evalway;
						break;
					case 't':
					case 'bt':
						const opts = c.opts || {};
						const opt0 = optstr[0][opts.opt1||0];
						a.params = {
							opt0,
							evalcons1: this.evalcons1,
							evalcons2: this.evalcons2,
							gastroPrompt1:this.gastroPrompt1,
							gastroPrompt2:this.gastroPrompt2,
							gastroPrompt3:this.gastroPrompt3
						};
						break;
					case 'z':
						a.params = { interpretway: this.interpretway };
						b.interpretway = this.interpretway;
						break;
				}
			},
			aftermake(date, a, b) {
				const f = async () => {
					let scheduleTime = date + ' ' + a.starttime;
					switch(a.tag) {
						case 'p':
						case 'p2':
							const c = this.selected[0];
							const opt = optstr[1][c.opts.opt2];
							await this.postNote({ label: 'AnesEvalAppointment', scheduleTime, templateSelector:b.evalway, cond:opt});
							break;
						case 't':
						case 'bt':
							await this.postNote({ label: 'EndoscopyAppointment', scheduleTime});
							const s = a.starttime.length < 5 ? '0' + a.starttime : a.starttime;
							scheduleTime = prevDate(date + 'T' + s + ':00+08:00') + 'T19:00:00+08:00';
							const db = this.$tcbapp.database();
							await db.collection('customer').doc(a.customerId).update({prepareDate:new Date(scheduleTime)});
							await this.postNote({ label: 'EndoscopyPrepareStart', scheduleTime});
							break;
						case 'z':
							await this.postNote({ label: 'PathologyAppointment', scheduleTime, templateSelector:b.interpretway});
							break;
					}
					await this.fetchData();
				};
				f();
			},
			aftercancel(date, a, customerId) {
				const f = async () => {
					await this.fetchData();
				};
				f();
			},
			getInfo(info) {
				if (!info||info.reserved) return "";
				if (!this.viewAll && !(this.consultantId === info.consultantId)) return "";
				const fn = v => v.name + ' (' + v.gender + v.age + '岁)';
				switch(info.tag) {
					case 'p':
					case 'p2':
						return fn(info) + '<br/>' + info.params.evalway;
					case 't':
					case 'bt':
						let s = fn(info) + '<br/>' + info.params.evalcons1;
						if (info.params.evalcons2) s += '<br/>' + info.params.evalcons2;
						if (info.params.gastroPrompt1) s += '<br/>' + '7天停抗凝';
						if (info.params.gastroPrompt2) s += '<br/>' + 'T日停降糖';
						if (info.params.gastroPrompt3) s += '<br/>' + '4包清肠药';
						return s;
					case 'z':
						return info.name + ' (' + info.params.interpretway + ')';
				}
				return '';
			},
			highlight(event) {
				return event.info.name === this.selected[0].pii.username;
			},
			*/
			setCurrentDate() {
				let dtstr = new Date(Date.now() + nowOffsetHours * 3600 * 1000);
				this.currentdate = formatDate(dtstr);
			},
		},
        components: { Splitpanes, Pane, Calendar2 }
    }
</script>

<style>
.splitpanes.default-theme .splitpanes__pane {
    background-color: white;
}
.splitpanes.default-theme .splitpanes__splitter {
    background-color: #eee;
}
.splitpanes.default-theme .splitpanes__splitter:after,
.splitpanes.default-theme .splitpanes__splitter:before {
    background-color: rgba(0, 0, 0, .25);
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter,
.default-theme .splitpanes--horizontal>.splitpanes__splitter {
    height: 8px;
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme.splitpanes--horizontal>.splitpanes__splitter:before,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:before {
    width: 60px;
    height: 1.5px
}
</style>