<template>
	<div v-if="authed">
        <v-data-table :headers="headers" :items="items" item-key="_id" :loading="loading" loading-text="加载中..."
			:options.sync="options" :server-items-length="totalItems"
			:footer-props="{'items-per-page-options':[6,8,10,12]}">
            <template v-slot:top>
				<div class="d-flex flex-wrap pa-2" style="gap:12px">
               		<v-select dense hide-details :items="['进行中','已完成']" v-model="stagefilter0" @change="loadData" style="flex: 0 1 auto;max-width:120px;"/>
               		<v-select dense hide-details label="已经过步骤" :items="steps" v-model="stagefilter1" @change="loadData" clearable :disabled="stagefilter0 !== '进行中'" style="flex: 0 1 auto;max-width:150px;"/>
               		<v-select dense hide-details label="未达到步骤" :items="steps" v-model="stagefilter2" @change="loadData" clearable :disabled="stagefilter0 !== '进行中'" style="flex: 0 1 auto;max-width:150px;"/>
					<v-select dense hide-details label="支付方" :items="['全部','自费','保险直付','保险自付']" v-model="insur" @change="loadData" style="flex: 0 1 auto;max-width:120px;"/>
					<v-text-field v-model="pname" label="姓名" placeholder="客户姓名" clearable dense hide-details
						append-icon="search" @click:append="loadData" @keyup.enter.native="loadData" style="flex: 0 1 auto;max-width:180px;"></v-text-field>
					<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
						transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="pdate1" label="预约手术日期起" readonly v-bind="attrs"
								v-on="on" dense hide-details clearable style="flex: 0 1 auto;max-width:150px;"></v-text-field>
						</template>
						<v-date-picker v-model="pdate1" @input="menu1 = false" no-title scrollable></v-date-picker>
					</v-menu>
					<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10"
						transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="pdate2" label="预约手术日期止" readonly v-bind="attrs"
								v-on="on" dense hide-details clearable style="flex: 0 1 auto;max-width:150px;"></v-text-field>
						</template>
						<v-date-picker v-model="pdate2" @input="menu2 = false" no-title scrollable></v-date-picker>
					</v-menu>
					<v-checkbox v-model="showArchive" label="显示已归档" dense hide-details @change="loadData"/>
				</div>
                <v-divider/>
            </template>
            <template v-slot:item.progress.order="{ item }">
                {{formatTime(item.progress.order)}}
            </template>
			<template v-slot:item.apntOprtDate="{ item }">
				{{formatTime(item.apntOprtDate)}}
			</template>
            <template v-slot:item.progress="{ item }">
				<v-stepper v-if="$vuetify.breakpoint.mobile" vertical>
						<template v-for="n in getStepLength(item)">
							<v-stepper-step :key="`${n}-step`" :step="n" :complete="getStepValue(item, n-1)" :color="getStepColor(item, n-1)" :rules="getStepRules(item, n-1)">
							{{ getStepText(item, n-1) }}
							</v-stepper-step>
							<v-stepper-content :step="n"/>
						</template>
				</v-stepper>
				<v-stepper v-else alt-labels>
					<v-stepper-header>
						<!--
						<template v-for="n in steps.length">
							<v-stepper-step :key="`${n}-step`" :step="n" :complete="!!getValue(item, steps[n-1].value)">
							{{ steps[n-1].text }}
							</v-stepper-step>
							<v-divider v-if="n !== steps.length" :key="n"></v-divider>
						</template>
						-->
						<template v-for="n in getStepLength(item)">
							<v-stepper-step :key="`${n}-step`" :step="n" :complete="getStepValue(item, n-1)" :color="getStepColor(item, n-1)" :rules="getStepRules(item, n-1)">
							{{ getStepText(item, n-1) }}
							</v-stepper-step>
							<v-divider v-if="n !== getStepLength(item)" :key="n"></v-divider>
						</template>
					</v-stepper-header>
				</v-stepper>
            </template>
            <template v-slot:item.progress.payment="{ item }">
                {{formatTime(item.progress.payment)}}
            </template>
            <template v-slot:item.idcard="{ item }">
                {{getMaskedIdcard(item)}}
            </template>
            <template v-slot:item.gender="{ item }">
                {{getGender(item)}}
            </template>
            <template v-slot:item.age="{ item }">
                {{getAge(item)}}
            </template>
            <template v-slot:item.opts="{ item }">
                {{getOptsStr(item)}}
            </template>
			<template v-slot:item.insurance="{ item }">
				{{getInsurance(item)}}
			</template>
            <template v-slot:item.sweep="{ item }">
                <v-icon v-show="canSweep" class="mr-2" @click.stop="showDialog(item)">horizontal_split</v-icon>
            </template>
        </v-data-table>
		<v-dialog v-model="dialog" width="360">
			<v-card>
				<v-card-title>归档</v-card-title>
				<v-card-text>
					<v-form :disabled="!!currentItem.hide">
						<v-radio-group dense hide-details label="归档原因" v-model="reason" class="mt-1">
							<v-radio v-for="n in reasons" :key="n" :label="n" :value="n"/>
						</v-radio-group>
						<v-text-field clearable label="备注" v-model="comment" class="pt-2 px-1"/>
						<span style="font-weight:bold" class="pl-1">注意：归档操作将清除此客户信息</span>
					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn color="primary" :disabled="!!currentItem.hide||!reason" :loading="loading" @click.stop="sweep">归档</v-btn>
					<v-btn color="primary" :disabled="!currentItem.hide" :loading="loading" @click.stop="unsweep">恢复</v-btn>
					<v-btn color="primary" outlined @click.stop="dialog=false">取消</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import {formatTime, formatDate, calculateAge, nowOffsetHours} from '../utils'
	import store from '../store.js'

    export default {
        name: 'Customer',
        data() {
            return {
                authed: false,
                headers: [
                    {text:'姓名', value:'pii.username', width:120},
                    {text:'进度', value:'progress', width:1400, sortable: false},
//                    {text:'诊所', value:'region', width:100},
//                    {text:'创建时间', value:'progress.order', width:180},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'授权信息', value:'consent', width:100},
                    {text:'付款确认时间', value:'progress.payment', width:180},
                    {text:'预约手术时间', value:'apntOprtDate', width:180},
                    {text:'证件号', value:'idcard', width:120},
                    {text:'客户电话', value:'pii.phone', width:120},
                    {text:'性别', value:'gender', width:80},
                    {text:'年龄', value:'age', width:80},
//                    {text:'微信名', value:'userInfo.nickName', width:120},
                    {text:'套餐内容', value:'opts', width:200, sortable: false},
                    {text:'手术医生', value:'endoscopyResult.doctorT', width:100},
                    {text:'病案号', value:'pid', width:80},
                    {text:'订单号', value:'_id', width:120},
                    {text:'订单类型', value:'orderType', width:120},
                    {text:'渠道', value:'orderSource', width:90},
                    {text:'支付方', value:'insurance', width:150},
                    {text:'归档', value:'sweep', width:100, sortable: false},
                ],
                items: [],
                loading: false,
				steps: [
					{text:'创建订单', value:'progress.order'},
					{text:'确认付款', value:'progress.payment'},
					{text:'健康问卷', value:'progress.qa'},
					{text:'出检查单', value:'progress.examlistIssued'},
					{text:'上传结果', value:'progress.eeresults'},
					{text:'收讫结果', value:'eeaccepted'},
					{text:'预约评估', value:'apntEvalDate'},
					{text:'术前评估', value:'progress.evalConclusion'},
					{text:'预约手术', value:'apntOprtDate'},
					{text:'发清肠药', value:'progress.deliverSupplies'},
					{text:'术前准备', value:'progress.prepare'},
					{text:'执行手术', value:'progress.operative'},
					{text:'病理返回', value:'progress.pathologyReturn'},
					{text:'预约解读', value:'apntPthlDate'},
					{text:'病理解读', value:'progress.pathology'}
				],
				steps_i: [	//保险
					{text:'创建订单', value:'progress.order'},
					{text:'支付确认', value:'progress.payment'},
					{text:'预约面诊', value:'apntEvalDate'},
					{text:'到院面诊', value:'progress.diagnosed'},
					{text:'出检查单', value:'progress.examlistIssued'},
					{text:'保险授权', value:'progress.preAuthorized'},
					{text:'上传结果', value:'progress.eeresults'},
					{text:'收讫结果', value:'eeaccepted'},
					{text:'术前评估', value:'evalConclusion'},
					{text:'预约手术', value:'apntOprtDate'},
					{text:'发清肠药', value:'progress.deliverSupplies'},
					{text:'术前准备', value:'progress.prepare'},
					{text:'执行手术', value:'progress.operative'},
					{text:'病理返回', value:'progress.pathologyReturn'},
					{text:'预约解读', value:'apntPthlDate'},
					{text:'病理解读', value:'progress.pathology'}
				],
				steps_d: [	//定向
					{text:'创建订单', value:'progress.order'},
					{text:'预约手术', value:'apntOprtDate'},
					{text:'发清肠药', value:'progress.deliverSupplies'},
					{text:'术前准备', value:'progress.prepare'},
					{text:'执行手术', value:'progress.operative'},
					{text:'病理返回', value:'progress.pathologyReturn'},
					{text:'预约解读', value:'apntPthlDate'},
					{text:'病理解读', value:'progress.pathology'}
				],
                canSweep: false,
				showArchive: false,
                dialog: false,
				currentItem: {},
				stagefilter0: '进行中',
				stagefilter1: '',
				stagefilter2: '',
				reasons: [
					"信息填写错误",
					"评估未通过",
					"客户放弃",
					"用于测试",
					"其他原因"
				],
				reason: null,
				comment: '',
				pname: '',
				pdate1: '',
				menu1: false,
				pdate2: '',
				menu2: false,
				insur: '全部',
				porderid: this.$route?.params?.orderid,
				options: {page: 1, itemsPerPage: 8},
				totalItems: 0
            }
        },
        mounted() {
            this.formatTime = formatTime;
//			this.pdate1 = formatDate(Date.now());
//			this.pdate2 = formatDate(Date.now());
			this.authed = this.$hasPrivilege(['全部客户总览','归档','健康顾问']);
			this.canSweep = this.$hasPrivilege(['归档','健康顾问']);
			this.$watch(()=>store.currentRegion, this.fetchData);
			this.fetchData();
        },
        methods: {
			getStepLength(item) {
				return item.orderSource === '定向' ? this.steps_d.length : (item.payment?.insurance ? this.steps_i.length : this.steps.length);
			},
			getStepColor(item, i) {
				return item.orderSource === '定向' ? 'purple' : (item.payment?.insurance ? 'teal accent-4' : 'primary');
			},
			getStepText(item, i) {
				return item.orderSource === '定向' ? this.steps_d[i].text : (item.payment?.insurance ? this.steps_i[i].text : this.steps[i].text);
			},
			getStepValue(item, i) {
				return !!this.getValue(item, item.orderSource === '定向' ? this.steps_d[i].value : (item.payment?.insurance ? this.steps_i[i].value : this.steps[i].value));
			},
			getStepRules(item, i) {
				if (item.payment?.insurance) {
					if (this.steps_i[i].value === 'progress.preAuthorized' && item.preAuthResult === '拒绝') {
						return [() => false];
					}
				}
				return undefined;
			},
			getValue: (object, keys) => keys.split('.').reduce((o, k) => (o || {})[k], object),
			getMaskedIdcard(item) {
//				return item.pii.idcard.replace(/^(.{3})(?:\d+)(.{3})$/,  "\$1****\$2");
				return item.pii.idcard;
			},
            getGender(item) {
                return item.pii.gender;
            },
            getAge(item) {
                return calculateAge(item.pii.dob, item.progress.order);
            },
            getOptsStr(item) {
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO;
            },
			getInsurance(item) {
				return item.payment.insurance && item.payment.insurer ? `${item.payment.insurer} - ${item.payment.insurance}` : '自费';
			},
			async loadData() {
				if (this.options.page !== 1) {
					this.options.page = 1;	//自动触发fetchData
					return;
				}
				await this.fetchData();
			},
            async fetchData() {
                const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [];
				if (this.stagefilter0 === '进行中') {
//					if (!this.stagefilter1 && !this.stagefilter2) {
					if (true) {
						filter.push(_.and([
							{'endoscopyResult.pathology' : _.neq('无')}, {'progress.pathology' : _.exists(false)}, {'giveupInterpretation':_.exists(false)}
						]))
					}
					if (this.stagefilter1) filter.push({[this.stagefilter1] : _.exists(true)});
					if (this.stagefilter2) filter.push({[this.stagefilter2] : _.exists(false)});
				} else {
					filter.push(_.or([{'endoscopyResult.pathology' : '无'}, {'progress.pathology' : _.exists(true)}, {'giveupInterpretation':_.exists(true)}]));
				}
				if (this.$hasPrivilege('全部客户总览')) {
				} else if (this.$hasPrivilege('健康顾问')) {
					const u = this.$getCurrentUser();
					filter.push({consultantId : u.consultantId});
				};
				/*
				if (this.$hasPrivilege('admin')) {
				} else if (this.$hasPrivilege('限制查看')) {
					const today = formatDate(Date.now());
					const t1 = new Date(today + 'T' + '00:00:00+08:00');
					const t2 = new Date(today + 'T' + '23:59:59+08:00');
					const p6d = formatDate(Date.now() - 86400 * 1000 * 6);
					const tp6 = new Date(p6d + 'T' + '00:00:00+08:00');
					filter.push(_.or([
						{apntEvalDate:_.gt(t1).lt(t2)},
						{apntOprtDate:_.gt(t1).lt(t2)},
						{apntPthlDate:_.gt(t1).lt(t2)},
						{apntClncDate:_.gt(t1).lt(t2)},
						{apntBldDate:_.gt(t1).lt(t2)},
						{'progress.operative':_.gt(tp6).lt(t2)},
					]));
				}
				*/
				if (this.insur === '自费') {
					filter.push({'payment.insurance': _.exists(false)});
				} else if (this.insur === '保险直付') {
					filter.push({'payment.insurance': '直付'});
				} else if (this.insur === '保险自付') {
					filter.push({'payment.insurance': '自付'});
				}
				if (this.pname) {
					filter.push({'pii.username' : this.pname});
					this.porderid = undefined;
				} else if (this.porderid) {
					filter.push({_id:this.porderid});
				}
				const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
				const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
				if (t1 && t2) {
					filter.push({apntOprtDate:_.gt(t1).lt(t2)});
				} else if (this.pdate1) {
					filter.push({apntOprtDate:_.gt(t1)});
				} else if (this.pdate2) {
					filter.push({apntOprtDate:_.lt(t2)});
				}
				if (!this.showArchive) {
					filter.push({'hide' : _.neq(true)});
				}
				filter.push({category:_.neq('clinic')});
				filter.push({region : store.currentRegion});
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(_.and(filter)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(_.and(filter))
						.skip((page-1) * itemsPerPage).limit(itemsPerPage).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.get();
					this.items = res.data;
                } catch (error) {
                    console.error(error);
                }
                this.loading = false;
			},
			showDialog(item) {
				this.currentItem = item;
				this.reason = null;
				this.dialog = true;
			},
			async sweep() {
				 if (!!this.currentItem.endoscopyResult && this.reason !== '用于测试') {
					this.$dialog.message.error('已完成手术订单不可归档');
					return;
				 }
				const id = this.currentItem._id;
				this.loading = true;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{
						funcname:'archiveOrder',
						data: {
							id,
							reason: this.reason,
							comment: this.comment
						}
					}});
					await this.fetchData();
					this.dialog = false;
				} catch(err) {
					console.error(err);				
				}
				this.loading = false;
			},
			async unsweep() {
				const id = this.currentItem._id;
				this.loading = true;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{
						funcname:'unarchiveOrder',
						data: {
							id,
						}
					}});
					await this.fetchData();
					this.dialog = false;
				} catch(err) {
					console.error(err);				
				}
				this.loading = false;
			}
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			pdate1(newv, oldv) {
				if (newv && this.pdate2) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 > t2) {
						this.pdate2 = newv;
					}
				}
				this.fetchData();
			},
			pdate2(newv, oldv) {
				if (newv && this.pdate1) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 > t2) {
						this.pdate1 = newv;
					}
				}
				this.fetchData();
			},
		}
    }
</script>

<style>
tr.v-data-table__selected {
	background: #e5eff9!important;
}
</style>